<script>
import CalcItem from "@/components/content/home/section1/calc/calc-item.vue";
import {mapGetters} from "vuex";

export default {
  name: "section1",
  components: {CalcItem},
  computed: {
    ...mapGetters(['getDrugs'])
  }
}
</script>

<template>
  <section class="section" :class="[{drug1: getDrugs === 0}, {drug1: getDrugs === null}, {drug2: getDrugs === 1}]"
           id="section1">
    <picture>
      <source media="(max-width: 940px)" srcset="@/assets/img/home/section1/bear-mob.png">
      <img class="bear-img" src="@/assets/img/home/section1/bear.png" alt="">
    </picture>
    <div class="content">
      <calc-item/>
    </div>

    <div class="pack-img">


      <img class="mob-bg" src="@/assets/img/home/section1/sign.png" alt="sign">
      <picture>
        <source media="(max-width: 940px)" srcset="@/assets/img/home/section1/pack1-mob.png">

        <img :class="[{active: getDrugs === 0 || getDrugs === null}]" class="pack-img__item pack-img__item-item1"
             src="@/assets/img/home/section1/pack1.png"
             alt="НУРОФЄН® ДЛЯ ДІТЕЙ">
      </picture>
      <picture>
        <source media="(max-width: 940px)" srcset="@/assets/img/home/section1/pack2-mob.png">

        <img :class="[{active: getDrugs === 1}]" class="pack-img__item pack-img__item-item2 "
             src="@/assets/img/home/section1/pack2.png"
             alt="НУРОФЄН® ДЛЯ ДІТЕЙ ФОРТЕ">
      </picture>
    </div>
  </section>
</template>

<style scoped lang="scss">

.section {
  max-width: 100%;
  width: 100%;
  overflow: hidden;

  min-height: calc(100vh);
  min-height: calc((var(--vh, 1vh) * 100) - 157px);
  position: relative;
  padding: 0 20px 80px 20px;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 940px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }

  .content {
    max-width: 48.2%;
    width: 100%;

    @media screen and (max-width: 940px) {
      max-width: 694px;
    }
  }

  will-change: backgroundColor;
  transition: background-color .3s ease;
  background-color: var(--gold, #FFA400);

  &.drug1 {
    background-color: var(--gold, #FFA400);
  }

  &.drug2 {
    background-color: var(--teal, #56C2C6);
  }

  .bear-img {
    position: absolute;
    left: -120px;
    top: 0;
    //top: 50%;

    //transform: translateY(-50%);

    max-height: 100%;
    max-width: 50%;
    width: auto;
    height: auto;
    display: block;
    object-fit: contain;
    pointer-events: none;

    @media screen and (max-width: 940px) {
      display: none;
      //position: relative;
      //left: 0;
      //max-height: 100%;
      //max-width: 100%;
    }
  }

  .pack-img {
    pointer-events: none;
    @media screen and (max-width: 940px) {
    }

    .mob-bg {
      display: none;
      @media screen and (max-width: 940px) {
        display: block;
        max-width: 388px;
        width: 100%;
        height: auto;
        padding: 27px 10px 90px 10px;
      }
    }

    @media screen and (max-width: 940px) {
      //display: none;
      position: relative;

      left: 0;
      right: 0;
      bottom: 0;
      max-height: 100%;
      max-width: 100%;
    }

    &__item {
      max-width: 27%;
      width: auto;
      max-height: 90%;
      height: auto;

      position: absolute;
      //top: 50%;
      top: 0;
      right: 0;

      object-fit: contain;

      transform: translate(100%, 0);

      transition: transform .5s ease-in-out;
      z-index: 1;


      @media screen and (max-width: 940px) {
        //position: relative;
        max-width: 100%;
        max-height: 100%;
        top: 60px;

        transform: translate(200%, 0);

      }

      &.active {
        z-index: 2;
        transform: translate(0, 0);
      }
    }
  }
}

</style>