<script>
import specPopup from "@/components/popup/specPopup.vue";
import topBlock from "@/components/header/top-block.vue";

export default {
  name: "app",
  components: {
    specPopup,
    topBlock,
  },
  data() {
    return {
      popup: true,
      mobile: false,
    }
  },
  mounted() {

    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      this.mobile = true
    }
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)) {
      this.mobile = true
    }

    if (isAndroid || iOS === true) {
      this.mobile = true
    }

    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener("resize", () => {

      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);

    });
  },
  methods: {
    popupYes() {
      this.popup = false
    },
  }
}
</script>

<template>
  <div class="wrapper" :class="[{ active: mobile }]">
    <topBlock/>
    <router-view/>
    <specPopup v-if="popup" @popup-yes="popupYes()"/>
  </div>
</template>

<style lang="scss">
@import "@/assets/style/vars";
@import "@/assets/style/fonts.css";
#app{
  background: white;
}

#onetrust-consent-sdk {
  * {
    font-family: 'NurofenText_W', sans-serif;
  }
}

.wrapper.active {
  cursor: none !important;
  outline: none !important;
}

body {
  background: #ffa400;
}
</style>
