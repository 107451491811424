import {createStore} from 'vuex'

export default createStore({
    state: {
        currentDrugs: null,
        recommendedDoses: null,
        maxDoses: null,

    },
    getters: {
        getDrugs: state => {
            return state.currentDrugs
        },
        getRecommendedDoses: state => {
            return state.recommendedDoses
        },
        getMaxDoses: state => {
            return state.maxDoses
        }
    },
    mutations: {
        switchDrugs(state, currentDrugs) {
            state.currentDrugs = currentDrugs
        },
        switchRecommendedDoses(state, recommendedDoses) {
            state.recommendedDoses = recommendedDoses
        },
        switchMaxDoses(state, maxDoses) {
            state.maxDoses = maxDoses
        },
        switchResult(state) {
            state.recommendedDoses = null
            state.maxDoses = null
        }
    },
    actions: {
        doDrugs({commit}, currentDrugs) {
            commit('switchDrugs', currentDrugs)
        },

        calculateDrugs({commit, state}, weight) {
            let calcRecommendedDoses = null
            let calcMaxDoses = null

            if (state.currentDrugs === 0) {
                calcRecommendedDoses = ((weight * 10) * 5) / 100
                calcMaxDoses = calcRecommendedDoses * 3
            } else if (state.currentDrugs === 1) {
                calcRecommendedDoses = ((weight * 10) * 5) / 200
                calcMaxDoses = calcRecommendedDoses * 3
            }

            commit('switchRecommendedDoses', calcRecommendedDoses)
            commit('switchMaxDoses', calcMaxDoses)
        },

        clearResult({commit}) {
            commit('switchResult')
        }
    },
    modules: {}
})
