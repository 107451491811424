<template>
  <div class="home">

    <section1/>

    <bottomBlock/>

  </div>
</template>

<script>
// @ is an alias to /src

import Section1 from "@/components/content/home/section1/section1.vue";
import bottomBlock from "@/components/content/home/bottom-block/bottom-block.vue";

export default {
  name: 'HomeView',
  components: {bottomBlock, Section1},

}
</script>

<style lang="scss" scoped>
</style>
