<script>
export default {
  name: "specPopup",
  data() {
    return {
      stage: 1
    }
  },

  setup(props, {emit}) {
    const emitEventYes = () => {
      emit('popup-yes')
    };
    // const emitEventNo = () => {
    //   emit('popup-no')
    // }

    return {emitEventYes}
  }
}
</script>

<template>
  <div class="popup-wrapper">

    <div class="popup">

      <div v-if="stage === 1">
        <h3 class="popup__title">Даний матеріал призначений виключно для спеціалістів сфери охорони здоров’я!</h3>
        <p class="popup__text">Натискаючи на кнопку <strong class="popup__text-bold">«так»</strong>, ви підтверджуєте,
          що
          є спеціалістом сфери
          охорони здоров’я</p>

        <div class="popup__buttons">
          <div class="popup__button popup__button-yes" @click="emitEventYes" id='popupYes'>
            <span>Так</span>
          </div>
          <div class="popup__button popup__button-no" @click="stage = 2" id='popupNo'>
            <span>Ні</span>
          </div>
        </div>
      </div>

      <div class="stage2" v-if="stage === 2">
        <h3 class="popup__title">На жаль, ви не можете продовжити користування нашим ресурсом!</h3>
        <p class="popup__text">Даний матеріал призначений виключно для спеціалістів сфери охорони здоров’я</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999999;

  background: rgba(36, 48, 69, 0.75);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .popup {
    max-width: 406px;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 40px;

    border-radius: 20px;
    background: var(--amber, #FC0);
    box-shadow: 20px 40px 50px -10px rgba(0, 0, 0, 0.50);

    @media screen and (max-width: 600px) {
      padding: 20px;
    }

    &__title {
      color: var(--navy, #243045);
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-bottom: 12px;

      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }

    &__text {
      color: var(--navy, #243045);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      margin-bottom: 24px;
      @media screen and (max-width: 600px) {
        font-size: 14px;
      }

      &-bold {
        font-weight: 700;
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    &__button {

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 11px 24px;

      border-radius: 40px;
      border: 2px solid var(--navy, #243045);


      cursor: pointer;

      will-change: border, background-color;

      transition: border .2s ease, background-color .2s ease;

      span {
        color: var(--navy, #243045);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        display: inline-block;
        //margin-top: 7px;

        will-change: color;

        transition: color .2s ease;

        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }

      &-yes {
        background: var(--navy, #243045);

        span {
          color: white;
        }
      }

      @media screen and (min-width: 1024px) {
        &:hover {
          border-color: var(--web-red, #CC0018);
          background-color: rgba(255, 255, 255, 0);

          span {
            color: var(--web-red, #CC0018) !important;
          }
        }
      }

      &:active {
        border-color: var(--web-red, #CC0018);
        background-color: #CC0018;

        span {
          color: var(--web-red, white) !important;;
        }
      }
    }
  }
}
</style>