<script>
export default {
  data() {
    return {
      links: false,
    }
  },
}
</script>

<template>
  <div class="links">
    <div class="links__switch" @click="links = !links">
      <p>ДЖЕРЕЛА</p>
      <img :class="{open:links}" class="links__switch--arrow" src="@/assets/img/home/bottom-block/notes/arrow.svg"
           alt="arrow">
    </div>
    <div class="links__drop" :class="{open:links}">
      <p class="links__footnotes">*Рекомендовані разові дози ібупрофену обчислені з розрахунком 10 мг на кг маси тіла,
        максимальні добові дози - з розрахунком 30 мг на кг маси тіла.
      </p>
      <ol class="links__list">
        <li class="links__link">Марушко Ю.В. Терапія лихоманки у дітей із гострими респіраторними захворюваннями. УМЧ;
          2018:1(1) (123) – I/II
        </li>
        <li class="links__link">Інструкція для медичного застосування лікарського засобу НУРОФЄН<sup>®</sup> ДЛЯ ДІТЕЙ
          суспензія
          оральна з апельсиновим смаком, 100 мг/5 мл, РП МОЗ UA/8233/01/01 від 25.07.2017 із змінами. Реккітт Бенкізер
          Хелскер (Юкей) Лімітед, Денсом Лейн, Східний Йоркшир, Халл, HU8 7DS, Велика Британія.
        </li>
        <li class="links__link">Інструкція для медичного застосування лікарського засобу НУРОФЄН<sup>®</sup> ДЛЯ ДІТЕЙ
          суспензія
          оральна з полуничним смаком, 100 мг/5 мл, РП МОЗ UA/7914/01/01 від 04.07.2017 із змінами. Реккітт Бенкізер
          Хелскер (Юкей) Лімітед, Денсом Лейн, Східний Йоркшир, Халл, HU8 7DS, Велика Британія.
        </li>
        <li class="links__link">Інструкція для медичного застосування лікарського засобу НУРОФЄН<sup>®</sup> ДЛЯ ДІТЕЙ
          ФОРТЕ
          суспензія оральна з апельсиновим смаком, 200 мг/5 мл, РП № UA/8233/01/02. Реккітт Бенкізер Хелскер (ЮКей)
          Лімітед, Денсом Лейн, Східний Йоркшир, Халл, HU8 7DS, Велика Британія.
        </li>
        <li class="links__link">Інструкція для медичного застосування лікарського засобу НУРОФЄН<sup>®</sup> ДЛЯ ДЛЯ
          ДІТЕЙ ФОРТЕ
          суспензія оральна з полуничним смаком, 200 мг/5 мл, РП МОЗ UA/7914/01/02 від 04.07.2017 із змінами. Реккітт
          Бенкізер Хелскер (ЮКей) Лімітед, Денсом Лейн, Східний Йоркшир, Халл, HU8 7DS, Велика Британія.
        </li>
        <li class="links__link">Fever in infants and children: Pathophysiology and management. Available at: <a
          class="links__link-link"
          href="https://www.uptodate.com/contents/fever-in-infants-and-children-pathophysiology-and-management?search=fever%20in%20children%20treatment&source=search_result&selectedTitle=1%7E150&usage_type=default&display_rank=1#H70205006"
          target="_blank">https://www.uptodate.com/contents/fever-in-infants-and-children-pathophysiology-and-management?search=fever%20in%20children%20treatment&source=search_result&selectedTitle=1%7E150&usage_type=default&display_rank=1#H70205006</a>
          (Last access:15.04.2024)
        </li>
        <li class="links__link">Лихоманка у дитини. Настанова 00594. Настанови на засадах доказової медицини. Режим
          доступу: <a class="links__link-link"
                      href="https://guidelines.moz.gov.ua/documents/3415" target="_blank">https://guidelines.moz.gov.ua/documents/3415</a>
          (Дата звернення: 15.04.2024)
        </li>
      </ol>
    </div>
  </div>
</template>

<style scoped lang="scss">
.links {
  max-width: 1028px;
  width: 100%;

  @media screen and (max-width: 768px) {
    order: 1;
    padding-bottom: 40px;
    border-bottom: 1px solid #D6D6D6;
  }

  &__switch {
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    p {
      color: var(--navy, #243045);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    &--arrow {
      //margin-top: -7px;
      width: 12px;
      height: auto;
      display: block;
      margin-left: 10px;
      will-change: rotate;
      transition: transform 0.2s ease;
      transform-origin: center;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &__footnotes {
    color: var(--navy, #243045);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  &__list {
    padding-left: 20px;
    overflow: visible;

    list-style-type: decimal;
    list-style-position: outside;
  }

  &__link {
    color: var(--navy, #243045);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &-link {
      color: var(--navy, #243045);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-decoration: none;

      @media screen and (min-width: 1024px) {
        &:hover {
          text-decoration: underline;
        }
      }

      &:active {
        text-decoration: underline;
      }
    }
  }

  &__drop {
    max-height: 0;
    overflow: hidden;

    &.open {
      max-height: 2000px;
    }
  }
}
</style>