<script>
import notes from '@/components/content/home/bottom-block/bottom-items/notes.vue'
import instruction from '@/components/content/home/bottom-block/bottom-items/instruction.vue'
import Links from "@/components/content/home/bottom-block/bottom-items/links.vue";

export default {
  name: "bottomBlock",
  components: {
    Links,
    notes,
    instruction
  }
}
</script>

<template>
  <footer class="bottom-block">
    <instruction/>

    <div class="bottom-block__sep"></div>
    <links/>
    <notes/>
  </footer>
</template>

<style scoped lang="scss">
.bottom-block {
  position: relative;
  z-index: 20;
  border-radius: 20px 20px 0 0;
  background: var(--grey, #EEE);
  padding: 40px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: -40px;


  &__sep {
    padding-top: 32px;
    width: 100%;
    height: 1px;
    border-top: 1px solid #D6D6D6;

    max-width: 1028px;

    @media screen and (max-width: 768px) {
      order: 3;
      padding-top: 8px;
    }
  }
}
</style>