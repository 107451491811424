<script>

</script>

<template>
  <div class="notes">

    <p class="notes__text notes__text-mt notes__text-mb">Матеріал призначений для спеціалістів сфери охорони здоров’я. У
      випадку виникнення небажаних
      реакцій звертайтеся за телефоном <a class="notes__text-link" href="tel:0800300338">0 800 300 338</a> або
      електронною адресою
      <a class="notes__text-link" href="mailto:ConsumerHealth_UA@reckitt.com">ConsumerHealth_UA@reckitt.com</a>. Дзвінки
      зі стаціонарних
      телефонів по Україні безкоштовні. Вартість дзвінків із мобільних телефонів визначається згідно з тарифами
      мобільного оператора.</p>
    <p class="notes__text">© 2024. Всі права захищені. Нурофєн<sup>®</sup></p>

  </div>
</template>

<style scoped lang="scss">
.notes {

  max-width: 1028px;
  width: 100%;

  @media screen and (max-width: 768px) {
    order: 4;
  }

  &__text {
    color: var(--navy, #243045);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.13px;

    &-link {
      color: var(--navy, #243045);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.13px;
      word-wrap: break-word;

      @media screen and (min-width: 1024px) {
        &:hover {
          text-decoration: none;
        }
      }

      &:active {
        text-decoration: none;
      }
    }

    &-mt {
      margin-top: 32px;
    }

    &-mb {
      margin-bottom: 20px;
    }
  }
}
</style>