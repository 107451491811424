<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "calc-item",
  computed: {
    ...mapGetters(['getDrugs', 'getRecommendedDoses', 'getMaxDoses']),
    hasInput() {
      return this.inputValue !== '';
    }
  },
  methods: {
    ...mapActions(['doDrugs', 'calculateDrugs', 'clearResult']),
    clearInput() {
      this.disabled = true;
      this.inputValue = '';
      this.error = '';
      this.isFocused = false;
      this.clearResult()
    },
    addInfo() {
      if (this.getDrugs === 0 && this.inputValue === '') {
        this.error = "Введіть вагу дитини в межах<br><b>від 5 до 40 кг</b>";
        this.disabled = true;
      } else if (this.getDrugs === 1 && this.inputValue === '') {
        this.error = "Введіть вагу дитини в межах<br><b>від 7 до 40 кг</b>";
        this.disabled = true;
      }
    }
  },
  data() {
    return {
      isFocused: false,
      inputValue: "",
      disabled: true,
      error: "",
    };
  },
  watch: {
    inputValue(newVal, oldVal) {
      let regex = /^\d*?\d*$/;
      if (!regex.test(newVal)) {
        this.inputValue = oldVal;
      }

      this.clearResult()

      let floatValue = parseFloat(newVal);

      if (isNaN(floatValue) && this.getDrugs === 0) {
        this.error = "Введіть вагу дитини в межах<br><b>від 5 до 40 кг</b>";
        this.disabled = true;
      } else if (isNaN(floatValue) && this.getDrugs === 1) {
        this.error = "Введіть вагу дитини в межах<br><b>від 7 до 40 кг</b>";
        this.disabled = true;
      } else if (this.getDrugs === 0 && floatValue < 5) {
        this.error = "Лікарський засіб рекомендовано застосовувати для дітей з масою тіла&nbsp;<b>≥5&nbsp;кг</b>";
        this.disabled = true;
      } else if (this.getDrugs === 0 && floatValue > 40) {
        this.error = "Для дітей з масою тіла <b>понад&nbsp;40&nbsp;кг</b> розгляньте <b>інші форми</b> НУРОФЄН<sup>®</sup>";
        this.disabled = true;
      } else if (this.getDrugs === 1 && floatValue < 7) {
        this.error = "Лікарський засіб рекомендовано застосовувати для дітей з масою тіла&nbsp;<b>≥7&nbsp;кг</b>";
        this.disabled = true;
      } else if (this.getDrugs === 1 && floatValue > 40) {
        this.error = "Для дітей з масою тіла <b>понад&nbsp;40&nbsp;кг</b> розгляньте <b>інші форми</b> НУРОФЄН<sup>®</sup>";
        this.disabled = true;
      } else {
        console.log(floatValue)
        this.error = "";
        this.disabled = false;
      }
    }
  },


}
</script>

<template>
  <div class="calc">
    <h2 class="calc__title">Розрахунок разової дози Нурофєн<sup>®</sup> для дітей</h2>

    <h2 class="calc__text">Оберіть форму препарату та вкажіть&nbsp;вагу&nbsp;дитини<sup>2-7</sup></h2>

    <div class="input-wrapper">
      <div id="nurofenChild" :class="{active: getDrugs === 0}" @click="doDrugs(0) ; clearInput()" class="calc__btn">
        <div class="calc__radio">
          <img v-if="getDrugs === 0" class="calc__radio-active" src="@/assets/img/home/section1/calc/active-btn.svg"
               alt="НУРОФЄН® ДЛЯ ДІТЕЙ">
        </div>
        <p class="calc__name">
          <b>НУРОФЄН<sup>®</sup> ДЛЯ ДІТЕЙ</b><br>
          100 мг/5 мл*
        </p>
      </div>
      <div id="nurofenChildForte" :class="{active: getDrugs === 1}" @click="doDrugs(1); clearInput()" class="calc__btn">
        <div class="calc__radio">
          <img v-if="getDrugs === 1" class="calc__radio-active" src="@/assets/img/home/section1/calc/active-btn.svg"
               alt="НУРОФЄН® ДЛЯ ДІТЕЙ ФОРТЕ">
        </div>
        <p class="calc__name">
          <b>НУРОФЄН<sup>®</sup> ДЛЯ ДІТЕЙ ФОРТЕ </b><br>
          200 мг/5 мл*
        </p>
      </div>

      <div class="calc__input" :class="{disabled:getDrugs === null}">
        <label for="my-input">

          <img v-if="inputValue !== ''" @click="clearInput()" class="calc__input--clear"
               src="@/assets/img/home/section1/calc/clear.svg"
               alt="clear">

          <span class="calc__input--placeholder"
                v-if="getDrugs === 0 || getDrugs === null"
                :class="{top: isFocused || hasInput}">Вага дитини, кг (min 5 кг)</span>

          <span v-if="getDrugs===1" class="calc__input--placeholder"
                :class="{top: isFocused || hasInput}">Вага дитини, кг (min 7 кг)</span>
        </label>
        <input :class="{error: error !==''}"
               id="my-input"
               type="text"
               @focus="isFocused = true;addInfo()"
               @blur="isFocused = false"
               v-model="inputValue">
      </div>


      <div class="calc__error-message" v-if="error !==''">
        <img class="calc__error-message--arrow" src="@/assets/img/home/section1/calc/error-arow.svg" alt="error">
        <p v-html="error"></p>
      </div>

      <div class="calc__result" v-if="getRecommendedDoses && getMaxDoses">

        <img class="calc__result-arrow" src="@/assets/img/home/section1/calc/result-arrow.svg" alt="arrow">

        <div class="calc__result-row">

          <div class="calc__result-col">
            <p class="calc__result-text">Рекомендована <b>разова</b>&nbsp;доза</p>
            <p class="calc__result-result">{{ getRecommendedDoses }} мл</p>

          </div>
          <div class="calc__result-col">
            <p class="calc__result-text">Максимальна <b>добова</b>&nbsp;доза</p>
            <p class="calc__result-result">{{ getMaxDoses }} мл</p>
          </div>

        </div>

        <div class="calc__result-row calc__result-row--second">
          <p class="calc__result-recommend">Не більше 3 разів на добу!</p>
        </div>


      </div>


      <div class="calc__resultBtn" id="calc"
           :class="[{disabled: disabled || (getRecommendedDoses && getMaxDoses)}]"
           @click="calculateDrugs(parseInt(inputValue))">
        <span>Розрахувати</span>
      </div>

    </div>

  </div>
</template>

<style scoped lang="scss">
.calc {
  //display: flex;
  //flex-direction: column;
  //align-items: center;

  max-width: 694px;
  width: 100%;
  margin: 80px auto 0 auto;

  @media screen and (max-width: 600px) {
    margin: 40px auto 0 auto;
  }

  &__title {
    color: var(--navy, #243045);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    margin-bottom: 32px;

    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  &__text {
    color: var(--navy, #243045);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    @media screen and (max-width: 600px) {
      font-size: 17px;
    }
  }

  .input-wrapper {
    max-width: 388px;
    width: 100%;
    height: auto;
    margin: 16px auto 0 auto;
  }

  &__btn {
    display: flex;
    align-items: center;

    width: 100%;

    border-radius: 60px;
    background: var(--white, #FFF);
    padding: 16px 40px;
    margin-bottom: 8px;

    cursor: pointer;
    transition: background-color .2s ease-in-out;
    will-change: background-color;

    @media screen and (max-width: 600px) {
      padding: 12px 24px;

    }
    @media screen and (min-width: 1024px) {
      &:hover {
        background-color: var(--amber, #FC0);

        .calc__radio {
          border-color: #CC0018;
        }
      }
    }

    &.active {
      background: var(--yellow, #FFED00);

      .calc__radio {
        border-color: #CC0018;
      }
    }
  }

  &__radio {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    position: relative;

    border: 2px solid #243045;
    border-radius: 50%;
    pointer-events: none;

    &-active {
      width: 34px;
      height: auto;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;

      transform-origin: center;
      transform: translate(-50%, -50%)
    }
  }

  &__name {
    font-family: 'NurofenText_W', sans-serif;
    color: var(--navy, #243045);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    pointer-events: none;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }

    b {
      font-family: inherit;
      color: var(--navy, #243045);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;

      //margin-top: 7px;
      display: inline-block;

      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  &__input {
    position: relative;

    &.disabled {
      //opacity: 0.2;
      pointer-events: none;
    }

    &--clear {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &--placeholder {
      position: absolute;
      pointer-events: none;
      left: 50%;
      top: 50%;

      transform: translate(-50%, -50%);
      width: 100%;

      color: var(--mid-grey, #6A7281);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      //margin-top: 3px;

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }

      &.top {
        left: 50%;
        top: 15px;

        color: var(--navy, #243045);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        transform: translate(-50%, -10px);

        @media screen and (max-width: 600px) {
          top: 10px;
          font-size: 13px;

          transform: translate(-50%, -5px);
        }
      }
    }

    input {
      width: 100%;
      padding: 20px 20px 7px 20px;

      color: var(--web-red, #231F20);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      border-radius: 60px;
      border: 4px solid var(--web-red, rgba(0, 0, 0, 0));
      background: var(--white, #FFF);

      @media screen and (max-width: 600px) {
        font-size: 18px;
        padding: 18px 20px 8px 20px;
      }

      &.error {
        color: #CC0018;

        &:focus {
          border: 4px solid var(--navy, #CC0018);
        }
      }

      &:focus {
        border: 4px solid var(--navy, #243045);
        outline: none;
      }
    }
  }

  &__resultBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 40px;
    background: var(--navy, #243045);

    margin-top: 16px;

    padding: 22px 24px;

    cursor: pointer;

    transition: background-color .2s ease-in-out;

    @media screen and (min-width: 1024px) {
      &:hover {
        background: white;

        span {
          color: var(--web-red, #CC0018);
        }
      }
    }

    &:active {
      background: var(--amber, #FC0);

      span {
        color: var(--web-red, #CC0018);
      }
    }

    @media screen and (max-width: 600px) {
      padding: 16px 20px 15px 20px;
    }

    span {
      pointer-events: none;

      color: var(--white-white, #FFF);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;

      display: inline-block;
      //margin-top: 7px;

      transition: color .2s ease-in-out;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  &__error-message {
    padding: 20px;
    background: var(--web-red, #CC0018);
    border-radius: 25px;
    margin-top: 8px;
    position: relative;

    @media screen and (max-width: 600px) {
      padding: 24px 28px;
    }

    &--arrow {
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);

      width: 12px;
      height: auto;
      display: block;
    }

    p {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      @media screen and (max-width: 600px) {
        font-size: 17px;
      }

      ::v-deep b {
        font-weight: 700;

      }
    }
  }

  &__result {
    border-radius: 25px;
    background: var(--yellow, #FFED00);
    padding: 20px 32px;
    margin-top: 8px;
    position: relative;

    &-arrow {
      position: absolute;
      left: 50%;
      top: -8px;
      transform: translateX(-50%);
    }

    &-row {
      display: flex;
      gap: 10px;

      &--second {
        padding-top: 12px;
        border-top: 1px dashed #243045;
      }
    }

    &-recommend {
      color: var(--navy, #243045);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    &-text {
      color: var(--navy, #243045);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      @media screen and (max-width: 600px) {
        font-size: 15px;
      }

      b {
        font-weight: 700;
      }
    }

    &-result {
      color: var(--navy, #243045);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      @media screen and (max-width: 600px) {
        font-size: 24px;
      }
    }
  }
}
</style>