<template>
  <header class="top-block">

    <div class="content">
      <router-link to="/" class="content__link">
        <img class="content__logo" src="@/assets/img/header/logo.png" alt="logo_nurofen">
      </router-link>
      <h1 class="content__title">Діє з 15 хвилини<br>та до 8 годин</h1>
    </div>

  </header>
</template>

<style scoped lang="scss">

.top-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .content {
    max-width: 1028px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    @media screen and (max-width: 768px) {
      justify-content: center;
      padding: 20px 0;
    }

    &__logo {
      max-width: 205px;
      width: 100%;
      height: auto;
      display: block;


      @media screen and (max-width: 768px) {
        max-width: 164px;
      }
    }

    &__title {
      color: var(--navy, #243045);
      font-size: 20px;
      font-style: normal;
      font-family: "NurofenText_W", sans-serif;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
</style>