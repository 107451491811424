<script>
export default {
  methods: {
    copyToClipboard() {
      var text = "https://nurofendosage.com.ua/";

      var tempInput = document.createElement("textarea");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  }
}
</script>

<template>
  <div class="instruction">
    <h4 class="instruction__title">Встановлення калькулятора на&nbsp;телефон</h4>
    <div class="instruction__row">
      <div class="instruction__step instruction__step-first">
        <div class="instruction__step--col">
          <p class="instruction__step--num">
            <span class="instruction__step--num-desk ">1</span>
            <span class="instruction__step--num-mob ">1</span>
          </p>
        </div>
        <div class="instruction__step--col">
          <p class="instruction__text">Відкрийте сторінку калькулятора в мобільному браузері (<span
            class="instruction__text-copy" @click="copyToClipboard()">скопіюйте</span> посилання або відскануйте QR
            код нижче)</p>
          <img src="@/assets/img/home/bottom-block/instruction/qr.png" alt="qr" class="instruction__qr">
        </div>
      </div>
      <div class="instruction__step">
        <div class="instruction__step--col">
          <p class="instruction__step--num">
            <span class="instruction__step--num-desk ">2</span>
            <span class="instruction__step--num-mob ">1</span>
          </p>
        </div>
        <div class="instruction__step--col">
          <p class="instruction__text">Натисніть на кнопку в браузері в залежності від платформи вашого пристрою
          </p>
          <div class="instruction__illustration instruction__illustration-mt instruction__illustration-mb">
            <img src="@/assets/img/home/bottom-block/step2-icon1.svg" alt="Android">
            <p class="instruction__illustration--text">Android</p>
          </div>
          <div class="instruction__illustration">
            <img src="@/assets/img/home/bottom-block/step2-icon2.svg" alt="iOS">
            <p class="instruction__illustration--text">iOS</p>
          </div>
        </div>
      </div>
      <div class="instruction__step">
        <div class="instruction__step--col">
          <p class="instruction__step--num">
            <span class="instruction__step--num-desk ">3</span>
            <span class="instruction__step--num-mob ">2</span>
          </p>
        </div>
        <div class="instruction__step--col">
          <p class="instruction__text">У меню, що відкриється, оберіть пункт <b>«Додати на головну сторінку»</b>
          </p>
          <div class="instruction__illustration instruction__illustration-mt instruction__illustration-mb">
            <img src="@/assets/img/home/bottom-block/step3-icon1.svg" alt="Android">
            <p class="instruction__illustration--text">Android</p>
          </div>
          <div class="instruction__illustration">
            <img src="@/assets/img/home/bottom-block/step3-icon2.svg" alt="iOS">
            <p class="instruction__illustration--text">iOS</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.instruction {

  padding-bottom: 32px;

  max-width: 1028px;
  width: 100%;

  @media screen and (max-width: 768px) {
    order: 2;
    margin-top: 40px;
  }

  &__title {
    color: var(--navy, #243045);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;

    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 48px;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__step {
    display: flex;

    &-first {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--num {
      display: flex;
      justify-content: center;
      align-items: center;

      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 14.152px;

      width: 32px;
      height: 32px;
      background: #243045;
      border-radius: 50%;

      margin-right: 12px;

      &-desk {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &-mob {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      span {
        //margin-top: 7px;
        //@supports (-webkit-touch-callout: none) {
        //  margin-top: 0;
        //}
      }
    }
  }

  &__text {
    color: var(--navy, #243045);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    //margin-top: 7px;
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    b {
      font-weight: 700;
    }

    &-copy {
      color: var(--navy, #243045);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      text-decoration-line: underline;

      cursor: pointer;


      @media screen and (min-width: 1024px) {
        &:hover {
          text-decoration: none;
        }
      }

      &:active {
        text-decoration: none;
      }
    }
  }

  &__qr {
    margin-top: 10px;
    max-width: 90px;
    width: 100%;
    height: auto;
    display: block;
  }

  &__illustration {
    display: flex;
    align-items: center;

    &--text {
      //margin-top: 6px;
      margin-left: 8px;

      color: #333;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    &-mt {
      margin-top: 12px;
    }

    &-mb {
      margin-bottom: 16px;
    }
  }
}
</style>